import axios from "axios";

const createCategory = async (data) => {
  try {
    const res = await axios.post(
      'http://154.53.166.190:3000/api/categories/create-category', data, // Burada data, JSON formatında gönderiliyor
      {
        headers: {
          'Content-Type': 'multipart/form-data', // Bu başlıkla form verisi gönderilecektir
        },
      }
    );
    return res.data; // Başarılı yanıtı döndür
  } catch (err) {
    console.error("Kategori oluşturulamadı:", err);
    throw err; // Hata durumunda exception fırlat
  }
};


const updateCategory = async (data) => {
  try {
    const res = await axios.post(
      'http://154.53.166.190:3000/api/categories/update-category', data, // Burada data, JSON formatında gönderiliyor
      {
        headers: {
          'Content-Type': 'multipart/form-data', // Bu başlıkla form verisi gönderilecektir
        },
      }
    );
    return res.data; // Başarılı yanıtı döndür
  } catch (err) {
    console.error("Kategori oluşturulamadı:", err);
    throw err; // Hata durumunda exception fırlat
  }
};

const deleteCategory = async (data) => {
  try {
    const res = await axios.post(
      'http://154.53.166.190:3000/api/categories/delete-category', data, // Burada data, JSON formatında gönderiliyor
      {
        headers: {
          'Content-Type': 'application/json',
        }
      }
    );
    return res.data; // Başarılı yanıtı döndür
  } catch (err) {
    console.error("Kategori oluşturulamadı:", err);
    throw err; // Hata durumunda exception fırlat 
  }
};


const getAllCategory = async () => {
  const res = await axios.get('http://154.53.166.190:3000/api/categories/allCategory');
  return res.data;
};

const getCategoryDetails = async (id) => {
  const res = await axios.get(`http://154.53.166.190:3000/api/categories/details/${id}`);
  return res.data;
};

export default {
  getAllCategory,
  getCategoryDetails,
  deleteCategory,
  createCategory,
  updateCategory
};
