<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <table class="table">
          <thead>
            <tr>
              <th>Fields</th>
              <th>Field</th>
              <th>Field</th>
              <th>Field</th>
              <th>Field</th>
            </tr>
          </thead>
          <tbody>
     
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>
  
  
  
  
<script>
import axios from "axios"
import categoryApi from "../../src/services/categoryApi"
export default {
  data() {
    return {
      category:[]
    }
  },

  mounted() {
    categoryApi.getCategories().then((response) => {
      category.push(response)
      console.log(response)
      console.log("denemes")
    });
  },

}
</script>